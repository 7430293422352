<template>
  <div class="mobile-services-form">
    <template v-if="showBetaFeatures">
      <v-row no-gutters class="mobile-global-form__primary-color">
        <v-col cols="12">
          <v-checkbox
            v-model="form.mobile_hide_employees"
            :label="$trans('wb_hide_booking_employees')"
            hide-details
            @change="updateHideEmployees"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="mobile-global-form__primary-color">
        <v-col cols="12">
          <services-field
            v-model="form.mobile_filtered_services"
            multiple
            deletable-chips
            small-chips
            return-ids
            @input="updateFilteredServices"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <dynamic-form
            :elements="form.mobile_custom_fields"
            @change="updateCustomFields"
          />
        </v-col>
      </v-row>
    </template>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.menu_services"
          :label="$trans('mobile_services_form_menu_services_label')"
          outlined
          hide-details
          maxlength="15"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mobile-services-form__logo">
      <v-col cols="12">
        <calendesk-image-select
          v-model="form.settings.tenants_mobile_image_services"
          outline
          :select-type="selectType.MOBILE_SERVICES_IMAGE"
          :label="$trans('mobile_services_form_logo_label')"
          :select-image-button-text="
            $trans('mobile_services_form_logo_select_button')
          "
          :display-remove-image-button="false"
          @input="changeImageClientConfigurationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.services_title"
          :label="$trans('mobile_services_form_services_title')"
          outlined
          hide-details
          maxlength="60"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.services_input_placeholder"
          :label="$trans('mobile_services_form_services_input_label')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.services_search_empty_screen_subtitle"
          :label="
            $trans(
              'mobile_services_form_services_search_empty_screen_subtitle_label'
            )
          "
          outlined
          hide-details
          maxlength="150"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.services_types_title"
          :label="$trans('mobile_services_form_services_types_title_label')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.cancel_booking_success_button"
          :label="
            $trans('mobile_services_form_cancel_booking_success_button_label')
          "
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import CalendeskImageSelect from "@/lib/calendesk-js-library/components/CalendeskImageSelect";
import selectType from "@/components/assets/selectType";
import DynamicForm from "@/components/editor/forms/DynamicForm/DynamicForm";
import { getItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";

export default {
  name: "MobileServicesForm",
  components: {
    ServicesField,
    DynamicForm,
    CalendeskImageSelect,
  },
  data() {
    return {
      form: {
        settings: {
          tenants_mobile_image_services: {},
          mobile_hide_employees: false,
          mobile_filtered_services: [],
          mobile_custom_fields: [],
        },
        translations: {
          services_title: "",
          menu_services: "",
          services_input_placeholder: "",
          services_search_empty_screen_subtitle: "",
          services_types_title: "",
          cancel_booking_success_button: "",
        },
      },
      selectType,
    };
  },
  computed: {
    ...mapGetters({
      getConfiguration: "mobileEditor/getConfiguration",
      getTranslations: "mobileEditor/getTranslations",
    }),
    showBetaFeatures() {
      return !!getItem("beta", false);
    },
  },
  watch: {
    getConfiguration() {
      this.updateFields();
    },
    getTranslations() {
      this.updateFields();
    },
  },
  mounted() {
    this.updateFields();
  },
  methods: {
    ...mapActions({
      changeConfiguration: "mobileEditor/changeConfiguration",
      updateTranslation: "mobileEditor/updateTranslation",
    }),
    updateFields() {
      if (!this.getConfiguration || !this.getTranslations) return;
      this.form.settings.tenants_mobile_image_services =
        this.getConfiguration.tenants_mobile_image_services;
      this.form.mobile_hide_employees =
        this.getConfiguration.mobile_hide_employees;
      this.form.mobile_filtered_services =
        this.getConfiguration.mobile_filtered_services;
      this.form.mobile_custom_fields =
        this.getConfiguration.mobile_custom_fields;

      this.form.translations.menu_services = this.getTranslations.menu_services;
      this.form.translations.services_title =
        this.getTranslations.services_title;
      this.form.translations.services_input_placeholder =
        this.getTranslations.services_input_placeholder;
      this.form.translations.services_search_empty_screen_subtitle =
        this.getTranslations.services_search_empty_screen_subtitle;
      this.form.translations.services_types_title =
        this.getTranslations.services_types_title;
      this.form.translations.cancel_booking_success_button =
        this.getTranslations.cancel_booking_success_button;
    },
    updateTranslationInput() {
      this.updateTranslation({ translations: { ...this.form.translations } });
    },
    changeImageClientConfigurationsBlur() {
      this.changeConfiguration({ settings: { ...this.form.settings } });
    },
    changeTranslationsBlur() {
      this.changeConfiguration({ translations: { ...this.form.translations } });
    },
    updateHideEmployees() {
      this.changeConfiguration({
        settings: { mobile_hide_employees: this.form.mobile_hide_employees },
      });
    },
    updateCustomFields: debounce(function (fields) {
      this.form.mobile_custom_fields = fields;
      this.changeConfiguration({
        settings: { mobile_custom_fields: this.form.mobile_custom_fields },
      });
    }, 1000),
    updateFilteredServices: debounce(function () {
      this.changeConfiguration({
        settings: {
          mobile_filtered_services: this.form.mobile_filtered_services,
        },
      });
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.mobile-services-form .row {
  margin-bottom: 2rem;
}
</style>
