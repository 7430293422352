<template>
  <div>
    <v-menu
      v-model="isShowingMenu"
      offset-y
      bottom
      left
      :close-on-content-click="false"
    >
      <template #activator="{}">
        <v-text-field
          dense
          readonly
          :value="getElementActivatorValue"
          outlined
          hide-details
          append-icon="$pencil"
          append-outer-icon="$trash-default"
          class="mb-2"
          :label="$trans('wb_element_title')"
          @click="isShowingMenu = !isShowingMenu"
          @click:append="isShowingMenu = !isShowingMenu"
          @click:append-outer="deleteElement"
          @input="updateElement"
        />
      </template>
      <v-card max-width="700">
        <v-list class="pb-0 mb-0">
          <v-list-item>
            <v-list-item-title>
              {{ $trans("wb_form_element_type_title") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-row justify="center" no-gutters>
              <v-col>
                <v-select
                  v-model="type"
                  hide-details
                  outlined
                  :items="selectElementTypes"
                  @change="updateType"
                />
              </v-col>
              <v-col class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="required"
                  class="mt-1 pt-0 ml-2"
                  hide-details
                  :label="$trans('wb_dynamic_form_should_require')"
                  @change="updateElement"
                />
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item v-if="type === elementTypes.RADIO" class="mt-4">
            <v-row>
              <v-col class="d-flex align-center">
                <v-text-field
                  v-model="currentRadioOptionValue"
                  dense
                  outlined
                  hide-details
                  :label="$trans('wb_radio_add_option')"
                  @keydown.enter="addRadioOption"
                />
                <v-btn
                  icon
                  class="ml-2"
                  x-large
                  color="green"
                  :disabled="!currentRadioOptionValue"
                  @click="addRadioOption"
                >
                  <v-icon>$plus-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item>
          <template v-if="radioOptions.length > 0">
            <v-list-item
              v-for="(option, index) in radioOptions"
              :key="option + index"
              class="my-0 py-0"
            >
              <v-row no-gutters>
                <v-col class="d-flex align-center my-0 py-0">
                  <div>{{ option }}</div>
                  <v-btn
                    icon
                    class="ml-2"
                    color="error"
                    @click="removeRadioButton(index)"
                  >
                    <v-icon>$trash</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </template>
          <template v-if="labelIsAvailable">
            <v-list-item>
              <v-list-item-title>
                {{ $trans("wb_form_label_info") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-text-field
                v-model="label"
                dense
                outlined
                hide-details
                :label="$trans('tag')"
                @input="updateElement"
              />
            </v-list-item>
          </template>
          <v-list-item>
            <v-list-item-title>
              {{ $trans("wb_form_description_info") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <tip-tap v-model="description" @input="updateElement" />
          </v-list-item>
          <template v-if="showServices">
            <v-list-item>
              <v-list-item-title>
                {{ $trans("field_only_for_services") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-select
                v-model="selectedServices"
                dense
                outlined
                hide-details
                multiple
                :item-text="$helpers.getServiceName"
                item-value="id"
                :items="services ? services : []"
                small-chips
                deletable-chips
                clearable
                :label="$trans('select')"
                @input="updateElement"
              />
            </v-list-item>
          </template>
          <template v-if="type === elementTypes.TEXT_FIELD">
            <v-list-item>
              <v-list-item-title>
                {{ $trans("wb_dynamic_form_value_equals") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-text-field
                v-model="equal"
                dense
                outlined
                hide-details
                :label="$trans('wb_dynamic_form_value_equals')"
                @input="updateElement"
              />
            </v-list-item>
          </template>
          <v-list-item class="mt-4">
            <v-btn depressed block @click="isShowingMenu = false">
              {{ $trans("close") }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TipTap from "@/lib/calendesk-js-library/components/TipTap.vue";
import helpers from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "DynamicFormElement",
  components: { TipTap },
  props: {
    element: {
      type: Object,
      required: true,
    },
    formType: {
      type: String,
      default: helpers.dynamicFormType.GENERAL,
    },
    disallowSelectedServices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elementTypes: helpers.bookingDynamicFormTypes,
      type: helpers.bookingDynamicFormTypes.TEXT_FIELD,
      label: null,
      description: null,
      required: false,
      equal: null,
      isShowingMenu: false,
      selectedServices: null,
      radioOptions: [],
      currentRadioOptionValue: null,
    };
  },
  computed: {
    ...mapGetters({
      services: "category/getServices",
    }),
    getElementActivatorValue() {
      if (this.labelIsAvailable && this.label) {
        return this.label;
      }

      return this.$trans(
        `wb_dynamic_form_element_type_${Number(this.type) + 1}`
      );
    },
    showServices() {
      return (
        this.formType === this.$helpers.dynamicFormType.BOOKING &&
        !this.disallowSelectedServices
      );
    },
    selectElementTypes() {
      const result = [];
      if (this.elementTypes) {
        const types = Object.keys(this.$helpers.bookingDynamicFormTypes).filter(
          (key) => !isNaN(Number(this.$helpers.bookingDynamicFormTypes[key]))
        );
        for (const type in types) {
          result.push({
            text: this.$trans(
              `wb_dynamic_form_element_type_${Number(type) + 1}`
            ),
            value: Number(type),
          });
        }
      }

      return result;
    },
    labelIsAvailable() {
      const labelAvailable = [
        this.$helpers.bookingDynamicFormTypes.TEXT_FIELD,
        this.$helpers.bookingDynamicFormTypes.TEXT_AREA,
      ];

      return labelAvailable.includes(this.type);
    },
  },
  watch: {
    element() {
      this.reload();
    },
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      this.label = this.element.label;
      this.description = this.element.description;
      this.type = this.element.type;
      this.required = this.element.required;
      this.equal = this.element.equal;
      this.selectedServices = this.element.services;
      this.radioOptions = this.element.radioOptions || [];
    },
    updateElement() {
      const data = {
        type: this.type,
        label: this.label,
        description: this.description,
        required: this.required,
        selectedServices: this.selectedServices,
        radioOptions: this.radioOptions,
        equal: this.equal,
      };

      this.$emit("change", data);
    },
    updateType(type) {
      this.type = type;

      if (type !== this.elementTypes.RADIO) {
        this.radioOptions = [];
        this.label = null;
      }

      if (type !== this.elementTypes.TEXT_FIELD) {
        this.equal = null;
      }

      if (type === this.elementTypes.EMAIL) {
        this.label = this.$trans("email");
      }

      if (type === this.elementTypes.PHONE) {
        this.label = this.$trans("phone");
      }

      this.updateElement();
    },
    deleteElement() {
      this.$emit("delete", true);
    },
    addRadioOption() {
      if (this.currentRadioOptionValue) {
        this.radioOptions?.push(this.currentRadioOptionValue);
        this.updateElement();
      }
    },
    removeRadioButton(index) {
      this.radioOptions.splice(index, 1);
      this.updateElement();
    },
  },
};
</script>

<style lang="scss" scoped></style>
