<template>
  <v-card outlined class="pa-2 pt-0 mb-4">
    <v-card-title>{{ $trans("form") }}</v-card-title>
    <v-container fluid class="pa-0">
      <v-row v-for="(element, index) in items" :key="index" no-gutters>
        <v-col cols="12" class="mb-2">
          <dynamic-form-element
            :disallow-selected-services="disallowSelectedServices"
            :form-type="formType"
            :element="element"
            @change="updateElement($event, index)"
            @delete="deleteElement($event, index)"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-btn color="primary" block @click="addElement">
            {{ $trans("wb_add_element") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DynamicFormElement from "@/components/editor/forms/DynamicForm/DynamicFormElement";
import helpers from "@/lib/calendesk-js-library/tools/helpers";

export default {
  components: { DynamicFormElement },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: helpers.dynamicFormType.GENERAL,
    },
    disallowSelectedServices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    elements() {
      this.reload();
    },
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      if (this.elements && this.elements.length > 0) {
        this.items = [...this.elements];
      }
    },
    updateElement(event, index) {
      this.items[index] = event;
      this.update();
    },
    deleteElement(event, index) {
      this.items.splice(index, 1);
      this.update();
    },
    addElement() {
      const data = {
        type: this.$helpers.bookingDynamicFormTypes.TEXT_FIELD,
        label: null,
        description: `<p>${this.$trans("wb_booking_note")}</p>`,
        required: false,
        selectedServices: null,
        radioOptions: null,
        equal: null,
      };

      this.items.push(data);
      this.update();
    },
    update() {
      this.$emit("change", this.$helpers.cloneObject(this.items));
    },
  },
};
</script>
